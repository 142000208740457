// export const apiUrl = "https://mmbh.herokuapp.com";
export const apiUrl = "https://backend.mobilemechanichub.com"; // old main
// export const apiUrl = "https://mobile-mechanic-hub-backend.herokuapp.com";
// export const apiUrl = "https://82ed-103-244-178-85.au.ngrok.io";
export const imageUrl = `${apiUrl}/api/images`;
export const pdfUrl = `${apiUrl}/api/pdf`;
export const voucherImageUrl = `${apiUrl}/img/vouchers`;

export const stripe_public_key =
  "pk_live_51JKyGOHOVyleiVHjRlo6C5U84qnearI7JaYn5hWwYbemmH2X2qSITNCoYfsaK8vgBdAzQiSdLox88MjKhBDX1QzK006q88diSu";

// export const stripe_public_key =
//   "pk_test_51JKyGOHOVyleiVHjaA244Cs5sUI3gGJZ9pm6klSDkediJYII64shOj68zOuTEZiQpBtiE48u3TpEvceLHMX4ZXJ300xvn4RpOR";

export const URL = (link) => {
  return `${apiUrl}/api/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const createFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

export const CheckRequiredField = (obj) => {
  let requiredFields = false;
  Object.keys(obj).forEach(function (key) {
    if (obj[key] == "") {
      requiredFields = true;
    }
  });
  return requiredFields;
};
